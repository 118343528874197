<template>
  <div>
    <!-- 首屏 -->
    <div class="menu-box" v-if="index == 0" @mousewheel="handelScroll">
      <Menu_first class="menu_header" :index="index" @changePage="changePage"></Menu_first>
      <Container_first class="menu_container"></Container_first>
    </div>
    <!-- 序屏 -->
    <div class="menu-box" v-if="index == 1" @mousewheel="handelScroll">
      <Menu_second class="menu_header" @changePage="changePage"></Menu_second>
      <Container_second class="menu_container"></Container_second>
    </div>
  </div>
  <!-- 切换双屏 -->
  <div class="change">
    <span :class="['icon01_statu0',index == 0 ? 'icon01_statu0' : 'icon01_statu1']" @click="changePage(0)">&nbsp;</span><br>
    <span :class="['icon02_statu0',index == 1 ? 'icon02_statu1' : 'icon01_statu0']" @click="changePage(1)">&nbsp;</span>
  </div>
</template>

<script>
/* eslint-disable */
import { onMounted, onUnmounted } from '@vue/runtime-core'
import { ref } from 'vue'
import Menu_first from '@/components/menu_first/menu_first.vue'
import Container_first from '@/components/menu_first/container_first.vue'
import Menu_second from '@/components/menu_second/menu_second.vue'
import Container_second from '@/components/menu_second/container_second.vue'
export default {
  name: 'Home',
  components: { Menu_first, Container_first, Menu_second, Container_second },
  setup(){
    // 当前页数
    const index = ref(0)
    // 总页数
    const sumIndex = ref(1)

    // 挂载时为页面开启鼠标监听
    onMounted(()=>{
      window.addEventListener('mousewheel',handelScroll)
    })
    // 卸载时清除页面鼠标监听
    onUnmounted(()=>{
      window.removeEventListener('mousewheel',handelScroll)
    })

    // 鼠标滚动事件 （组件之间切换）
    function handelScroll(m){
      console.log(m)
      // 如果鼠标y坐标大于0且当前页小于最后一页时候则进行下一页
      if(m.deltaY > 0 && index.value < sumIndex.value){
        index.value ++;
        // console.log('进入第'+index.value+'页了',m)
      }else if(m.deltaY > 0 && index.value == sumIndex.value){ //如果鼠标y坐标大于0且当前页等于最后一页则停留在当前页
        index.value = sumIndex.value;
        // console.log('已经是最后一页了',m)
      }else if(m.deltaY < 0 && index.value != 0){ //如果鼠标y坐标小于0且当前页不是第一页
        index.value --;
        // console.log('进入第'+index.value+'页了',m)
      }else{
        index.value = 0;
        // console.log('已经是首页了',m)
      }
    }

    function changePage(){
      index.value = index.value == 0 ? 1 : 0
      // console.log('切换页面了。。',index.value)
    }

    return{
      index,
      sumIndex,
      handelScroll,
      changePage,
    }
  }
};
</script>

<style lang="less" scoped>
.menu-box{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  .menu_header{
    width:1900px;
    height: 85px;
    left: 0;
    top: 0;
  }
  .menu_container{
    width: 100%;
    height: 100%;
    
  }
}
.change{
  position: fixed;
  width: 50px;
  height: 60px;
  right: 30px;
  top: 40%;
  background-color: transparent;
  .icon01_statu0{
    border: none;
    width: 10px;
    height: 20px;
    line-height: 30px;
    margin-left: 20px;
    background-color: #861B20;
    border-radius: 30%;
    cursor: pointer;
  }.icon01_statu1{
    border: none;
    width: 10px;
    height: 20px;
    line-height: 30px;
    margin-left: 20px;
    background-color: #ccc;
    border-radius: 30%;
    cursor: pointer;
  }
  .icon02_statu0{
    border: none;
    width: 10px;
    height: 20px;
    line-height: 30px;
    margin-left: 20px;
    background-color: #ccc;
    opacity: 0.8;
    border-radius: 30%;
    cursor: pointer;
  }
  .icon02_statu1{
    border: none;
    width: 10px;
    height: 20px;
    line-height: 30px;
    margin-left: 20px;
    background-color: #861B20;
    opacity: 0.8;
    border-radius: 30%;
    cursor: pointer;
  }
}
</style>