<template>
  <div class="container">
    <div class="contain-box">
      <video
        src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/d5abb13d-b0fd-40c1-b92c-14ed51528d2c.mp4"
        autoplay="autoplay"
        loop="loop"
        muted="muted"
      ></video>
      <img
        src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/0fedbdeb-4272-46ae-ac5b-dcdca343f84b.png"
        alt="中心图片"
        class="center_img"
      />
      <div class="Qr-code-img">
        <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/e704e99c-c0bc-46f0-ae50-306b1ad86b00.jpg" alt="小程序二维码">
      </div>
    </div>
    <BySide></BySide>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue'
import { NInput, NSpace } from "naive-ui"
import BySide from "../menu_first/bySide.vue"

export default defineComponent({
  name: "container_first",
  components: { NInput, NSpace, BySide },
  setup() {
    return {
    }
  },
});
</script>

<style lang="less" scoped>
@theme_color:#861B20;
.container {
  display: flex;
  .contain-box {
    position: relative;
    width: 100%;
    height: 100%;
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
    .bg_img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
    .center_img {
      width: 689px;
      height: 142px;
      position: relative;
      left: 25%;
      top: 350px;
    }
    .Qr-code-img{
      width: 100px;
      height: 100px;
      position: fixed;
      left: 45%;
      top: 70%;
      border: 20px solid #fff;
      border-radius: 30px;
      opacity: 1;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>