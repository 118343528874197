<template>
  <div class="mobile">
    <div class="menu-box">
      <div class="logo">
        <img
          src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/b59973e9-1235-45bd-8f7e-739aaa3f4432.png"
          alt="完美先生"
        />
      </div>
      <div class="title-box">
        <span class="title1" @click="change('#top')">首页</span>
        <span class="title2" @click="change('#introduce')">公司简介</span>
      </div>
    </div>

    <div class="container-first" id="top">
      <div class="contain-box">
        <img
          src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/0fedbdeb-4272-46ae-ac5b-dcdca343f84b.png"
          alt="中心图片"
          class="center_img"
        />
        <div class="Qr-code-img">
            <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/e704e99c-c0bc-46f0-ae50-306b1ad86b00.jpg" alt="小程序二维码">
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="left-box">
        <img
          class="website_img"
          src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/45340e26-41c9-43ce-b022-e66294dd6f94.jpg"
          alt=""
        />
      </div>
      <div class="right-box" id="introduce">
        <div class="text-box">
          <!-- <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/b59973e9-1235-45bd-8f7e-739aaa3f4432.png" alt="完美先生logo"><br> -->
          <span class="title1">
            青春
            <span class="title-next">不常在</span> </span
          ><br />
          <span class="title-next2">
            抓紧
            <span class="title2">谈恋爱</span>
          </span>
          <br />
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;完美先生是国内著名的婚恋平台品牌,截至2022年2月，
            完美先生婚恋品牌已有注册会员3782万 ，
            在国内数十个城市设有线下实体门店及特约服务中心。
          </span>
          <br />
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;完美先生采用创新的大数据运营模式，致力于帮助单身人士实现完美脱单。
            旗下完美派对、完美心理更可为会员提供轻社交及微心理调整等帮助，
            以期帮助会员利用最少的时间、最愉悦的心情实现脱单目标。
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2022年完美先生婚恋平台强势登陆中国央视平台，并通过全面诚信认证广告。
            完美先生是一家严肃、正规的婚恋平台，
            已经有大量的会员通过完美先生找到了人生中的另一半或者改变了婚姻价值观。<br />
          </span>
          <br />
          <span>
            <span class="corporateValues">企业价值观<br /></span>
            打造真实、严肃婚恋平台；<br />
            倡导真诚、愉悦脱单生活；<br />
          </span>
        </div>
      </div>
      <div class="foot-box">
        <div class="foot-text">
          <span>版权所有：合缘信息科技(深圳)有限公司</span>
          <br />
          <span
            >备案号：
            <a href="http://beian.miit.gov.cn" target="_blank"
              >粤ICP备2021149615号-2</a
            >
          </span>
          <br />
          <span>客服电话：4000081520</span>
          <br />
          <span>官方微博：@完美先生婚恋</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "MobileView",
  setup() {
    function change(seletor) {
      document.querySelector(seletor).scrollIntoView({
        behavior: "smooth",
      });
    }
    function goMiniProgram() {
      window.location.href("www.baidu.com");
    }
    return {
      change,
    };
  },
};
</script>

<style lang="less" scoped>
@title_color333: #333;
@bg_colorfff: #fff;
@theme_color: #861b20;
@title_color333: #333;
@text_color666: #666;
@bg_colorfff: #fff;
@theme_color: #861b20;
@keyframes faseInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mobile {
  overflow: scroll;
  width: 100%;
  height: 100%;
  .menu-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 5rem;
    display: flex;
    background: @bg_colorfff;
    box-shadow: 0 0 8px #ccc;
    .logo {
      position: relative;
      padding-left: 2rem;
      width: 80px;
      height: 80px;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title-box {
      width: 60%;
      height: 80px;
      font-weight: bold;
      line-height: 80px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-left: 2rem;
      .title1 {
        width: 2rem;
        position: relative;
        padding: 0px;
        padding-bottom: 5px;
        color: @title_color333;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        border-bottom: 2px solid @theme_color;
        border-radius: 5px;
      }
      .title2 {
        position: relative;
        color: @title_color333;
        width: 4rem;
        height: 30px;
        line-height: 30px;
        padding-bottom: 5px;
        border-bottom: 2px solid @theme_color;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .container-first {
    display: flex;
    .contain-box {
      position: relative;
      width: 100%;
      height: 100%;
      background: url('https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/7e46300c-a97d-4b14-9d87-714adf91a87b.jpg') no-repeat;
      background-size: 100% 100%;
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      .bg_img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      .center_img {
        width: 15rem;
        height: 5rem;
        position: absolute;
        left: 20%;
        top: 30%;
      }
      .Qr-code-img {
        width: 10rem;
        height: 10rem;
        position: absolute;
        left: 25%;
        top: 55%;
        border: 20px solid #fff;
        border-radius: 30px;
        opacity: 1;
        img {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
  }

  .container-box {
    width: 100%;
    height: 100%;
    background-color: @bg_colorfff;
    display: flex;
    flex-wrap: wrap;
    .left-box {
      width: 100%;
      height: 25rem;
      border: none;
      .website_img {
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }
    .right-box {
      width: 100%;
      height: 55rem;
      position: relative;
      text-align: center;
      border: none;
      border-radius: 50px;
      .text-box {
        width: 90%;
        margin: 5%;
        height: 100%;
        position: absolute;
        text-align: left;
        background: url("https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/ecf3c0f4-89ce-4d3b-8a0a-9ccb633af9aa.webp")
          no-repeat;
        background-size: 50% 20%;
        background-position: 170px 620px;
        animation: faseInAnimation ease-out 0.6s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        .title1 {
          font-size: 1.5rem;
          color: @theme_color;
          margin-left: 30%;
          line-height: 3rem;
          font-weight: bold;
          .title-next {
            font-size: 1.3rem;
            color: @title_color333;
          }
        }
        .title2 {
          font-weight: bold;
          font-size: 1.5rem;
          color: @theme_color;
          line-height: 3rem;
        }
        .title-next2 {
          font-weight: bold;
          font-size: 1.3rem;
          color: @title_color333;
          margin-left: 40%;
        }
        span {
          font-size: 1rem;
          line-height: 2.5rem;
          color: @text_color666;
          .corporateValues {
            color: @theme_color;
            font-size: 1.3rem;
            font-weight: bold;
          }
        }
      }
    }
    .foot-box {
      width: 100%;
      height: 10rem;
      background-color: @bg_colorfff;
      .foot-text {
        color: @text_color666;
        font-size: 0.8rem;
        height: 100%;
        span {
          margin: 5%;
          line-height: 2rem;
          a {
            color: @text_color666;
          }
        }
      }
    }
  }
}
</style>