<template>
  <div class="menu-box">
    <div class="logo">
        <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/b59973e9-1235-45bd-8f7e-739aaa3f4432.png" alt="完美先生">
    </div>
    <div class="title-box">
        <span class="title1" @click="change">首页</span>
        <span class="title2">公司简介</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'menu_second',
  emit:["changePage"],
  setup(props,context){
    // 调用父组件的方法，并传值给父组件，更改父组件index的值为0，回到首页
    function change(){
      context.emit("changePage")
    }
    return{
      change
    }
  }
}
</script>

<style lang="less" scoped>
@title_color333:#333;
@bg_colorfff:#fff;
@theme_color:#861B20;
.menu-box{
    position: fixed;
    top:0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 85px;
    display: flex;
    background-color: @bg_colorfff;
    border-radius: 0px 0px 10px 10px;
    // opacity: 0.6;
    .logo{
        position: relative;
        padding-left: 150px;
        width: 100px;
        height: 100px;
        display: inline-block;
        z-index: 2;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .title-box{
        width: 400px;
        height: 82px;
        padding-left: 400px;
        padding-top: 30px;
        font-weight: bold;
        .title1{
            position: relative;
            padding: 0px;
            margin: 40px;
            padding-bottom: 5px;
            color: @title_color333;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            // border-bottom: 2px solid @theme_color;
            border-radius: 5px;
        }
        .title2{
            position: relative;
            padding: 0px;
            margin-left: 140px;
            padding-bottom: 5px;
            color: @title_color333;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            border-bottom: 2px solid @theme_color;
            border-radius: 5px;
        }
    }
}
</style>
