<template>
  <router-view>
  </router-view>
</template>

<script>
import router from './router'
import { onMounted } from 'vue'
/* eslint-disable */
export default {
  name: 'App',
  setup(){
    function isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      localStorage.setItem('ismobile',flag ? 1 : 0 )
      return flag
    }

    onMounted(()=>{
      if(isMobile()){
        router.push('/MobileView')
      }else{
        router.push('/Home')
      }
    })

    return {
      isMobile
    }
  }
}
</script>

<style>
html,body{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
* {
    padding: 0;
    margin:0;
    width: 100%;
    height: 100%;
    
  }

</style>
