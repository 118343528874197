import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MobileView from '@/views/MobileView'
/* eslint-disable */
const routes = [
  {
    path: '/',
    name: 'index',
    component:Home
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/MobileView',
    name: 'MobileView',
    component: MobileView
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// router.beforeEach((to,from,next)=>{
//   const ismobile = localStorage.getItem('ismobile')
//   if(ismobile === null){
//     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
//     localStorage.setItem('ismobile',flag ? 1 : 0)
//     ismobile = flag ? 1 : 0
//   }

//   // pc
//   if(ismobile === 0){
//     if(to.path === '/'){
//       next('/Home')
//     }else{
//       next()
//     }
//   }

//   // 手机
//   if(ismobile === 1){
//     if(to.path === '/'){
//       next('/MobileView')
//     }else{
//       next()
//     }
//   }
// })

export default router
