<template>
  <div class="Side">
    <div class="leftBySide" v-show="flag">
      <div class="kefu-detail" v-show="index == 1">
        <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/9fb7747e-4c9b-44ae-a80f-6be9a0b97b82.png" alt="客服二维码">
        <br>
        <span>扫一扫咨询客服</span>
      </div>
      <div v-show="index == 2">
        <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/e704e99c-c0bc-46f0-ae50-306b1ad86b00.jpg" alt="小程序二维码">
        <br>
        <span>扫一扫体验一下</span>
      </div>
      <div v-show="index == 3">
        <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/517e145a-0fa5-445a-9f0b-81155e95f4e2.png" alt="公众号二维码">
        <br>
        <span>扫一扫关注最新动态</span>
      </div>
    </div>
    <div class="rightBySide">
      <ul>
        <li class="Wx-kefu" @mouseenter="handleMouseEnter(kefuIndex)" @mouseleave="handleMouseLeave">
          <img
            src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/edb4ff57-1eec-4a34-be7b-e64b97725036.png"
            alt="客服"
          />
          <br />
          <span>客服</span>
          <br />
        </li>
        <li class="Wx-miniProgram" @mouseenter="handleMouseEnter(miniProgramIndex)" @mouseleave="handleMouseLeave">
          <img
            src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/b1038db6-2452-40df-9896-356a8aa8302b.png"
            alt="小程序"
          />
          <br />
          <span>小程序</span>
        </li>
        <li class="Wx-public" @mouseenter="handleMouseEnter(WxPublicIndex)" @mouseleave="handleMouseLeave">
          <img
            src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/5fe0d80d-2e35-40e1-8c28-e376a15246ba.png"
            alt="公众号"
          />
          <br />
          <span> 公众号</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from '@vue/runtime-core'
import { ref } from 'vue'
/* eslint-disable */
export default {
  name: "bySide",
  setup() {
    // 变量
    let flag = ref(false)  //控制左侧图片区域的开关
    let kefuIndex = ref(1) //控制客服详情的下标
    let miniProgramIndex = ref(2)  //控制小程序详情的下标
    let WxPublicIndex = ref(3)   //控制微信公众号详情的下标
    let index = ref(0)  //当前鼠标触及的下标

    // 挂载时添加监听事件
    onMounted(() => {
      window.addEventListener("mouseenter", handleMouseEnter)
      window.addEventListener("mouseleave", handleMouseLeave)
    })
    // 卸载时移除监听事件
    onUnmounted(() => {
      window.removeEventListener("mouseenter", handleMouseEnter)
      window.removeEventListener("mouseleave", handleMouseLeave)
    })

    // 方法
    // 鼠标移入展示详情事件
    function handleMouseEnter(e){
      // console.log(e)
      flag.value = !flag.value
      if(e == 1){
        // console.log('展开客服详情',e)
          index.value = e
      }else if(e == 2){
        index.value = e
      }else if(e ==3){
        // console.log('展开公众号详情',e)
        index.value = e
      }
    }
    
    // 鼠标移出隐藏所有详情页信息
    function handleMouseLeave(){
      flag.value = !flag.value
      index.value = 0
    }

    return {
      flag,
      index,
      kefuIndex,
      miniProgramIndex,
      WxPublicIndex,
      handleMouseEnter,
      handleMouseLeave
    }
  }
}
</script>

<style lang="less" scoped>
@theme_color:#861B20;
@bg_colorfff:#fff;
@text_color999:#999;
.Side {
  width: 200px;
  height: 600px;
  position: fixed;
  right: 30px;
  top: 100px;
  background-color: transparent;
  display: flex;
  .leftBySide {
    width: 150px;
    height: 190px;
    z-index:100;
    position: fixed;
    left: 82%;
    top: 60%;
    border-radius: 30px;
    background-color: @bg_colorfff;
    text-align: center;
    img{
      padding-top: 20px;
      width: 100px;
      height: 120px;
    }
    span{
      color: @theme_color;
      font-size: 12px;
    }
  }
  .rightBySide {
    width: 50px;
    height: 260px;
    position: fixed;
    right: 2.5%;
    top: 55%;
    border-radius: 30px;
    background-color: @bg_colorfff;
    ul {
      position: relative;
      left: 0px;
      top: 0px;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      height: 100%;
      // white-space: nowrap;
      font-size: 10px;
      border-radius: 30px;
      background-color: @bg_colorfff;
      li {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 50px;
        height: 50px;
        display: inline-block;
        color: @text_color999;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>