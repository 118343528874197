<template>
  <div class="container-box">
    <div class="left-box">
      <img class="website_img" src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/45340e26-41c9-43ce-b022-e66294dd6f94.jpg" alt="">
    </div>
    <div class="right-box">
      <div class="text-box">
        <!-- <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/b59973e9-1235-45bd-8f7e-739aaa3f4432.png" alt="完美先生logo"><br> -->
        <span class="title1">
          青春
          <span class="title-next">不常在</span>
          </span><br>
        <span class="title-next2">
          抓紧
          <span class="title2">谈恋爱</span>
          </span>
        <br>
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;完美先生是国内著名的婚恋平台品牌,截至2022年2月，
          完美先生婚恋品牌已有注册会员3782万 ，
          在国内数十个城市设有线下实体门店及特约服务中心。
        </span>
        <br>
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;完美先生采用创新的大数据运营模式，致力于帮助单身人士实现完美脱单。
          旗下完美派对、完美心理更可为会员提供轻社交及微心理调整等帮助，
          以期帮助会员利用最少的时间、最愉悦的心情实现脱单目标。
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2022年完美先生婚恋平台强势登陆中国央视平台，并通过全面诚信认证广告。
          完美先生是一家严肃、正规的婚恋平台，
          已经有大量的会员通过完美先生找到了人生中的另一半或者改变了婚姻价值观。<br>
        </span>
        <br>
        <!-- <span>
          完美先生-成就完美婚姻生活<br>
          完美派对-助力完美脱单<br>
          完美心理-为健康，我们需要更用心<br><br>
        </span> -->
        <!-- <span>
          企业的使命<br>
          和谐社会、幸福婚姻的践行者<br><br>
        </span> -->
        <!-- <span>
          企业的愿景<br>
          帮助更多会员步入幸福的婚姻生活<br><br>
        </span> -->
        <span>
          <span class="corporateValues">企业价值观<br></span>
          打造真实、严肃婚恋平台；<br>
          倡导真诚、愉悦脱单生活；<br>
        </span>
      </div>
    </div>
    <div class="foot-box">
      <div class="foot-text">
        <span>版权所有：合缘信息科技(深圳)有限公司</span>
        <span>备案号：
          <a href="http://beian.miit.gov.cn" target="_blank">粤ICP备2021149615号-2</a>
        </span>
        <br><br>
        <span>客服电话：4000081520</span>
        <span>官方微博：@完美先生婚恋</span>
      </div>
    </div>
    <BySide></BySide>
</div>
</template>

<script>
/* eslint-disable */
import {reactive} from 'vue'
import BySide from '../menu_first/bySide.vue'
export default {
    name: "container_second",
    components:{BySide},
    setup() {
        const logo = reactive([{
                name: "小程序",
                iconPath: ""
            }, {
                name: "公众号",
                iconPath: ""
            }, {
                name: "客服",
                iconPath: ""
            }
        ]);
        return {
            logo
        };
    },
}
</script>

<style lang="less" scoped>
@title_color333:#333;
@text_color666:#666;
@bg_colorfff:#fff;
@theme_color:#861B20;
@keyframes faseInAnimation{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.container-box{
  background-color: @bg_colorfff;
  display: flex;
  .left-box{
    width: 60%;
    border: none;
    .website_img{
      position: relative;
      left: 0px;
      top: 80px;
      width: 775px;
      height: 700px;
    }
  }
  .right-box{
    width: 40%;
    position: relative;
    text-align: center;
    border: none;
    border-radius: 50px;
    .text-box{
      width: 400px;
      height: 600px;
      position: absolute;
      top: 100px;
      left: 20px;
      text-align: left;
      font-size: 10px;
      background: url('https://vkceyugu.cdn.bspapp.com/VKCEYUGU-07226087-afdc-473f-9492-995a7c884c81/ecf3c0f4-89ce-4d3b-8a0a-9ccb633af9aa.webp') no-repeat;
      background-size: 50% 30%;
      background-position: 200px 420px;
      animation: faseInAnimation ease-out 0.6s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      img{
        width: 150px;
        height: 150px;
        margin-left: 10%;
      }
      .title1{
        font-size: 20px;
        color: @theme_color;
        margin-left: 30%;
        line-height: 50px;
        font-weight: bold;
        .title-next{
        font-size: 16px;
        color: @title_color333;
      }
      }
      .title2{
        font-weight: bold;
        font-size: 20px;
        color: @theme_color;
        line-height: 50px;
      }
      .title-next2{
        font-weight: bold;
        font-size: 16px;
        color: @title_color333;
        margin-left: 40%;
      }
      span{
        line-height: 25px;
        color: @text_color666;
        line-height: 35px;
        .corporateValues{
          color: @theme_color;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .foot-box{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: @bg_colorfff;
    .foot-text{
      color: @text_color666;
      font-size: 14px;
      text-align: center;
      padding-top: 20px;
      span{
        padding-left: 40px;
        a{
          color: @text_color666;
        }
      }
    }
  }
}
</style>